import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 954.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,954.000000) scale(0.100000,-0.100000)">
          <path d="M4635 5629 c-126 -39 -141 -199 -25 -260 30 -17 59 -19 234 -19 184
0 201 -2 233 -21 69 -43 87 -141 37 -206 -47 -61 -57 -63 -425 -63 -285 0
-336 -2 -365 -16 -98 -46 -109 -184 -21 -248 27 -20 44 -21 377 -26 332 -5
350 -6 376 -26 50 -37 68 -70 68 -124 0 -54 -18 -87 -68 -124 -26 -20 -44 -21
-312 -26 l-285 -5 -41 -43 c-39 -43 -40 -45 -36 -103 3 -45 10 -65 29 -85 48
-53 58 -54 474 -54 l386 0 58 -30 c192 -97 427 -104 637 -20 202 81 366 250
441 456 54 150 52 245 -8 317 -81 98 -244 101 -324 5 -22 -27 -55 -112 -55
-145 0 -32 -57 -127 -100 -167 -58 -54 -107 -80 -184 -96 -157 -32 -322 53
-397 207 -19 40 -23 65 -24 143 0 82 4 103 26 151 49 104 142 178 256 205 79
18 109 33 145 71 30 31 58 100 58 142 0 90 -75 187 -163 209 -56 15 -956 15
-1002 1z"/>
<path d="M6042 5479 c-66 -33 -93 -82 -102 -183 -16 -166 -87 -238 -252 -255
-100 -10 -139 -28 -175 -82 -67 -102 -25 -236 89 -284 63 -26 134 -14 188 32
46 38 61 76 70 176 7 75 14 97 37 131 48 68 101 97 196 105 98 10 144 27 180
70 48 57 58 131 28 196 -49 106 -156 144 -259 94z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
